<template>
  <div class="task-add">
    <!-- <div class="main-Title bgff"><h2>Edit Advertiser</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover">
        <el-form ref="postData" :model="postData" :rules="rules" label-width="110px">
          <el-form-item label="广告主名称" prop="advertiserName"
            ><el-input v-model="postData.advertiserName" placeholder="请输入广告主名称"
          /></el-form-item>
          <el-form-item label="广告主编号" prop="advertiserCode"
            ><el-input v-model="postData.advertiserCode" placeholder="请输入广告主编号"
          /></el-form-item>
          <el-form-item label="源" prop="advertiserSource"
            ><el-input v-model="postData.advertiserSource" placeholder="请输入源"
          /></el-form-item>
          <el-form-item label="备注" prop="remarks"
            ><el-input v-model="postData.remarks" placeholder="请输入备注"
          /></el-form-item>
          <el-form-item align="center">
            <el-button :loading="loading.btn" size="small" type="primary" @click="add('postData')"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
  import { getAdvertiserDetails, handleAdvertiserUpdate } from '@/api/DDJ/v3/advertiser/index.js';
  export default {
    data() {
      return {
        postData: {
          id: this.$route.query.id,
        },
        options: {},
        loading: {
          btn: false,
        },
        rules: {
          advertiserName: [
            {
              required: true,
              message: '请输入广告主名称',
              trigger: 'blur',
            },
          ],
          advertiserCode: [
            {
              required: true,
              message: '请输入广告主编号',
              trigger: 'blur',
            },
          ],
          advertiserSource: [
            {
              required: true,
              message: '请输入源',
              trigger: 'blur',
            },
          ],
        },
      };
    },
    mounted() {
      this.details();
    },
    created() {},
    methods: {
      // 获取详情
      details() {
        getAdvertiserDetails({ id: this.postData.id }).then((res) => {
          this.postData = res.result;
        });
      },
      // 保存
      add(formName) {
        this.$refs[formName].validate((valid) => {
          if (!valid) return;
          handleAdvertiserUpdate(this.postData).then((res) => {
            console.log(res);
            this.$message({
              message: '更新成功',
              type: 'success',
            });
            this.$router.push({
              path: '/click/advertiser/v3',
            });
          });
        });
      },
    },
  };
</script>

<style></style>
